export const SYSTEM_ADMIN = 'SYSTEM_ADMIN';

export const CONFIG_TENANT_ADMIN = 'CONFIG_TENANT_ADMIN';

export const LOGICPRINT5_LABELLING = 'LOGICPRINT5_LABELLING';
export const LOGICPRINT5_ORDERS = 'LOGICPRINT5_ORDERS';
export const LOGICPRINT5_SKU = 'LOGICPRINT5_SKU';
export const LOGICPRINT5_LABELS = 'LOGICPRINT5_LABELS';
export const LOGICPRINT5_REQUESTS = 'LOGICPRINT5_REQUESTS';
export const LOGICPRINT5_SETTINGS = 'LOGICPRINT5_SETTINGS';
