import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import store from './store';

export default {
  // eslint-disable-next-line no-unused-vars
  install(Vue) {
    const options = {
      accessTokenFactory: () => store.getters['oidcStore/oidcAccessToken'],
    };

    const connection = new HubConnectionBuilder()
      .withUrl('/NotificationHub', options)
      .configureLogging(LogLevel.Error)
      .build();

    // use new Vue instance as an event bus
    const notificationHub = new Vue();
    // every component will use this.$questionHub to access the event bus
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$notificationHub = notificationHub;

    async function start() {
      try {
        await connection.start().then(() => {
          notificationHub.$emit('connection-status', {
            title: 'connection-status',
            message: 'connected',
          });
        });
      } catch (err) {
        notificationHub.$emit('connection-status', {
          title: 'connection-status',
          message: 'reconnecting',
        });
        setTimeout(start, 5000);
      }
    }

    connection.onclose(async () => {
      notificationHub.$emit('connection-status', {
        title: 'connection-status',
        message: 'disconnected',
      });
      await start();
    });

    // Forward server side SignalR events through $questionHub, where components will listen to them
    connection.on('Notification', (title, message) => {
      notificationHub.$emit('new-notification', { title, message });
    });

    // eslint-disable-next-line no-param-reassign,func-names
    Vue.prototype.$startNotificationHub = function () {
      start();
    };
  },
};
