<template>
  <div>
    <div class="edit-position">
      <button
        v-if="!editForm"
        class="btn btn-light edit-button"
        style="text-align: center"
        type="button"
        @click="$emit('update:editForm', true)"
      >
        <i class="fas fa-pen" />
        {{ $t('general.editDetails') }}
      </button>
    </div>

    <div class="label-value-row">
      <div class="label">
        {{ $t('sku.sku') }} {{ $t('general.id') }}
      </div>
      <div class="value">
        {{ sku.externalId || '-' }}
      </div>
    </div>

    <div class="label-value-row">
      <div class="label">
        {{ $t('general.name') }}
      </div>
      <div class="value">
        {{ sku.name || '-' }}
      </div>
    </div>

    <div class="label-value-row">
      <div class="label">
        {{ $t('general.description') }}
      </div>
      <div class="value">
        <BFormInput
          v-if="editForm"
          v-model="description"
          class="value-value"
          style="margin-block: -20px"
        />
        <div
          v-else
        >
          {{ description || '-' }}
        </div>
      </div>
    </div>

    <div class="label-value-row">
      <div class="label">
        {{ $t('general.modified') }}
      </div>
      <div class="value">
        {{ formatDate(sku.modified) || '-' }}
      </div>
    </div>

    <div class="label-value-row">
      <div class="label">
        {{ $t('general.created') }}
      </div>
      <div class="value">
        {{ formatDate(sku.created) || '-' }}
      </div>
    </div>

    <hr>

    <SkuPropertiesList
      :sku-properties="skuPropertiesUsedInLabels"
      :edit-form="editForm"
      :header="$t('general.requiredData')"
      :unfolded-by-default="true"
      @update:property="propertyUpdated($event)"
    />

    <hr>

    <SkuPropertiesList
      :sku-properties="skuPropertiesNotUsedInLabels"
      :edit-form="editForm"
      :header="$t('general.auxiliaryData')"
      :unfolded-by-default="false"
      @update:property="propertyUpdated($event)"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import SkuPropertiesList from './SkuPropertiesList';

export default {
  props: {
    editForm: Boolean,
    sku: Object,
  },
  data: () => ({
    allSkuProperties: [],
    description: null,
  }),
  components: {
    SkuPropertiesList,
  },
  computed: {
    ...mapState({
      skuPropertyTypes: state => state.skuProperties.types,
      plantTimezone: state => state.settings.data?.plantTimeZone || null,
      skuPropertyTypeLabelUsages: state => state.skuPropertyTypeLabelUsages.list || [],
      allLabelMappings: state => state.labels.mappings,
    }),
    skuPropertiesUsedInLabels() {
      return this.allSkuProperties
        .filter(p => this.isPropertyTypeUsedInAnySkuLabel(p.skuPropertyTypeId));
    },
    skuPropertiesNotUsedInLabels() {
      return this.allSkuProperties
        .filter(p => !this.isPropertyTypeUsedInAnySkuLabel(p.skuPropertyTypeId));
    },
    skuPropertyTypesUsedInMappedLabelsIds() {
      const skuLabelIds = this.allLabelMappings
        ?.filter(m => m.skuId === this.sku.id)
        ?.map(m => m.labelId) || [];
      return this.skuPropertyTypeLabelUsages
        ?.filter(labelUsage => skuLabelIds.includes(labelUsage.labelId))
        ?.flatMap(labelUsage => labelUsage.propertyTypeIds) || [];
    },
  },
  watch: {
    allLabelMappings() {
      this.prepareSkuPropertiesLists();
    },
    description() {
      this.$emit('update:description', this.description);
    },
  },
  methods: {
    ...mapActions([
      'getSkuProperties',
    ]),
    formatDate(date) {
      if (!date) return '-';
      const plantUtcOffset = moment.tz(this.plantTimezone).utcOffset();
      return date ? moment.utc(date).add(plantUtcOffset, 'minutes').format('ll LTS') : '-';
    },
    propertyUpdated(updatedProperty) {
      const property = this.allSkuProperties.find(prop => prop.id === updatedProperty.id);
      property.value = updatedProperty.value;
      property.editable = updatedProperty.editable;
      property.isValid = updatedProperty.isValid;
      this.$emit('update:property', property);
    },
    prepareSkuPropertiesLists() {
      this.allSkuProperties = this.skuPropertyTypes
        .map(propType => {
          const skuProperty = this.sku.skuProperties
            .find(x => x.skuPropertyTypeId === propType.id);
          return {
            ...propType,
            type: propType.type || 'IMPORT',
            editable: skuProperty ? skuProperty.editable : propType.type.toUpperCase() !== 'IMPORT',
            exists: !!skuProperty,
            id: skuProperty ? skuProperty.id : `type${propType.id}`,
            value: skuProperty ? skuProperty.value : null,
            skuPropertyTypeId: propType.id,
            isValid: true,
          };
        })
        .sort((a, b) => (a.type === 'IMPORT' ? -1 : 1));
    },
    isPropertyTypeUsedInAnySkuLabel(skuPropertyTypeId) {
      return this.skuPropertyTypesUsedInMappedLabelsIds.includes(skuPropertyTypeId);
    },
  },
  created() {
    this.description = this.sku.description;
    this.prepareSkuPropertiesLists();
  },
};
</script>

<style lang="scss" scoped>

  .label-value-row {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;

    .label {
      width: 30%;
      font-size: 12px;
      font-weight: 450;
      color: #979797;
    }

    .value {
      display: flex;
      align-items: center;
      width: 60%;

      .value-value {
        padding-left: 10px;
        width: 90%;
      }
    }
  }

  .edit-position {
    text-align: right;
    padding-right: 145px;
  }

  .edit-button {
    max-width: 300px;
    border-radius: 18px;
    color: #7F7F7F;
    background-color: #EFEFEF;
    font-size: 12px;
    font-weight: 450;
    padding-top: 4px;
    padding-bottom: 4px;
    position: fixed;
  }

</style>
