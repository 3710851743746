export default {
  general: {
    name: 'Pavadinimas',
    description: 'Apibūdinimas',
    save: 'Išsaugoti',
    delete: 'Ištrinti',
    deleted: 'Ištrinta',
    removed: 'Pašalinta',
    select: 'Pasirinkti',
    selected: 'Pasirinkta',
    cancel: 'Atšaukti',
    confirm: 'Patvirtinti',
    search: 'Ieškoti',
    value: 'Reikšmė',
    error: 'Klaida',
    saved: 'Išsaugota',
    valueType: 'Reikšmės tipas',
    download: 'Parsiųsti',
    load: 'Apkrova',
    results: 'Rezultatai',
    type: 'Tipas',
    statuses: 'Būsenos',
    status: 'Būsena',
    orderStatus: 'Užsakymo būsena',
    created: 'Sukurtas',
    modified: 'Pakeistas',
    edited: 'Redaguotas',
    enabled: 'Įjungta',
    disabled: 'Išjungta',
    success: 'Įvykdyta',
    update: 'Atnaujinti',
    ordinalNumber: 'Eilės numeris',
    yes: 'Taip',
    no: 'Ne',
    on: 'Įjungta',
    off: 'Išjungta',
    archive: 'Archyvas',
    all: 'Visi',
    areYouSure: 'Ar tikrai?',
    id: 'ID',
    multiOrderSupport: 'Multi Order',
    from: 'Nuo',
    to: 'Iki',
    auto: 'Automatinis',
    manual: 'Rankinis',
  },
  date: {
    date: 'Data',
    startDate: 'Pradžios data',
    endDate: 'Pabaigos data',
    print: 'Spausdinimo data',
  },
  auth: {
    signOut: 'Atsijungti',
    loginError: 'Prisijungimo klaida',
  },
  sidebar: {
    labelling: 'Etikečių spausdinimas',
    sku: 'SKU',
    labels: 'Sąsajos',
    orders: 'Užsakymai',
    printRequests: 'Spausdinimo užklausos',
    settings: 'Nustatymų meniu',
  },
  settings: { users: 'Vartotojai', plantConfiguration: 'Gamyklos nustatymai', priority: 'Prioritetas' },
  sku: {
    sku: 'SKU',
    turnOffSync: 'Išjungti sinchronizavimą',
    enableSync: 'Įjungti sinchroniavimą',
    fields: 'SKU laukai',
    mappingSaved: 'Sąsajos išsaugotos',
    showAllFields: 'Rodyti visus SKU laukus',
    selectSkuField: 'Pasirinkite SKU lauką',
    clickToShowDeleted: 'Paspauskite jei norite parodyti ištrintą SKU',
    select: 'Pasirinkti SKU',
  },
  dictionary: {
    dictionaries: 'Žodynas',
  },
  labelTemplates: 'Etikečių šablonai',
  labelTemplate: 'Etiketės šablonas',
  label: {
    label: 'Etiketė',
    labels: 'Etiketės',
    refreshLabelTemplates: 'Atnaujinti etiketės šabloną',
    refreshLabelPreview: 'Atnaujinti etiketės peržiūrą',
    download: 'Atsiųsti etiketę',
    noLabel: 'Nėra etiketės',
    previewNotAvailable: 'Peržiūra negalima',
  },
  mapper: {
    skuFields: 'SKU laukai',
    thisCanBreakTheRules: 'Ši operacija gali nutraukti etiketės taisykles!',
    mappedSKU: 'Priskirti SKU',
    selectToPreview: 'Paspauskite jei norite peržiūrėti',
    selectSkus: 'Pasirinkite norimus peržiūrėti SKU',
    useSkuOrOrderDot: 'Naudokite SKU arba užsakymo Nr. kad gauti laukus',
    ruleSaved: 'Taisyklė išsaugota',
    ruleSavedComment: 'Taisyklė sėkmingai išsaugota',
  },
  template: { fields: 'Šablono laukai', field: 'Šablono laukas', selectToEdit: 'Paspauskite jei norite redaguoti' },
  error: { internal: 'Vidinė klaida', invalidPassword: 'Neteisingas slaptažodis' },
  printerMapping: { printerMapping: 'Spausdintuvų sąsaja', printersMappings: 'Spausdintuvų sąsajos' },
  printRequest: {
    printRequest: 'Spausdinti užklausą',
    printRequests: 'Spausdinti užklausas',
    sendAgainToPrinter: 'Siųsti dar kartą į spausdintuvą',
    status: {
      CREATED: 'Sukurta',
      SCHEDULED: 'Planuojama',
      INPROGRESS: 'Vykdoma',
      COMPLETED: 'Užbaigta',
      FAILED: 'Nepavyko',
      ARCHIVE: 'Archyvas',
    },

  },
  printer: {
    printer: 'Spausdintuvas',
    printers: 'Spausdintuvai',
    selectPrinter: 'Pasirinkite spausdintuvą',
    mappings: 'Spausdintuvų sąsajos',
    mapping: 'Spausdintuvų sąsaja',
    type: 'Spausdintuvo tipas',
    printManually: 'Spausdinti rankiniu būdu',
  },
  line: {
    line: 'Linija',
    lines: 'Linijos',
    lineName: 'Linijos pavadinimas',
    selectLine: 'Pasirinkite liniją',
  },
  labellingPoint: {
    labellingPoint: 'Spausdintuvas',
    labellingPoints: 'Spausdintuvai',
    selectLabellingPoint: 'Pasirinkite spausdintuvą',
    availableLabellingPoints: 'Galimi spausdintuvai',
  },
  form: {
    form: 'Forma',
    refresh: 'Atnaujinti',
    send: 'Siųsti',
    printSend: 'Spausdinti/Siųsti',
    title: 'Pavadinimas',
    dataForm: 'Duomenų forma',
    values: 'Reikšmės',
    dataSource: 'Duomenų šaltinis',
    url: 'Įveskite internetinį adresą pasiekti svarstyklėms',
    orderFieldInfo: 'Pasirinkite užsakymo lauką',
    orderField: 'Užsakymo laukas',
    label: 'Etiketė',
    required: 'Reikalinga',
    checkedByDefault: 'Patikrinta pagal nutylėjimą',
    description: 'Apibūdinimas',
    valuesCommaSeparated: 'Vertės (atskirtos kableliais)',
    text: 'Tekstas',
    input: 'Įvedimas',
    select: 'Pasirinkti',
    textarea: 'Teksto vieta',
    checkbox: 'Pasirinkite lauką',
    checklist: 'Kontrolinis sąrašas',
    radios: 'Pasirinkite lauką',
    textField: 'Nekeičiamas tekstas',
    addField: 'Pridėti lauką',
    activeFileds: 'Aktyvūs laukai',
    selectFieldType: 'Pasirinkite lauko tipą',
    fieldType: 'Lauko tipas',
    inputType: 'Pasirinkite įvesties tipą',
    labelValue: 'Įveskite klausimo / lauko pavadinimą',
    descriptionPrompt: 'Įveskite aprašymą / istrukciją',
    fieldDescription: 'Lauko aprašymas',
    fieldValues: 'Įveskite lauko reikšmes',
    name: 'Vardas',
    type: 'Tipas',
    editField: 'Pasirinkite lauką redagavimui',
    startField: 'Pridėkite lauką redagavimui',
  },
  lineLabellingPoint: { lineLabellingPoint: 'Priskirkite printerį', lineLabellingPoints: 'Priskirkite printerius' },
  order: {
    activeOrders: 'Aktyvūs užsakymai',
    actions: 'Veiksmai',
    details: 'Detalės',
    orderActivated: 'Užsakymas įjungtas:',
    order: 'Užsakymas',
    orders: 'Užsakymai',
    end: 'Pabaigti užsakymą',
    putOrderNumber: 'Įveskite užsakymo numerį',
    stop: 'Sustabdyti',
    start: 'Pradėti',
    activate: 'Aktyvinkite užsakymą',
    orderNumber: 'Užsakymo numeris',
    orderDetails: 'Užsakymo detalės',
    fields: 'Užsakymo laukai',
    field: 'Užsakymo laukas',
    add: 'Pridėti užsakymą',
    status: {
      CLOSED: 'Uždaryta',
      INPROGRESS: 'Vykdomas',
      COMPLETED: 'Užbaigtas',
      READY: 'Pateiktas',
      ACTIVATED: 'Aktyvuotas',
    },
  },
  selectors: {
    period: 'Laikotarpis',
    moment: 'Intervalas',
    dateTo: 'Data iki',
    dateFrom: 'Data nuo',
    shift: 'Pamaina',
    day: 'Diena',
    week: 'Savaitė',
    month: 'Mėnuo',
    today: 'Šiandien',
    yesterday: 'Vakar',
    thisWeek: 'Šią savaitę',
    previousWeek: 'Praėjusią savaitę',
    thisMonth: 'Šį mėnesį',
    previousMonth: 'Praėjusį mėnesį',
    lastThreeMonths: 'Paskutiniai 3 mėnesiai',
    selectExactDates: 'Pasirinkite tikslią datą',
  },
  time: { days: 'Dienos', fromPrint: 'Laikas nuo spausdinimo' },
  production: {
    ofThePlan: '%{value}% nuo numatytos reikšmės jau yra pasiekta',
    toAchieveTarget: 'Numatoma gamyba pasiekti tikslą yra:',
    noPlan: 'Nėra plano',
  },
};
