export default {
  general: {
    name: 'Nazwa',
    shortName: 'Skrócona nazwa',
    addName: 'Dodaj nazwę',
    addDescription: 'Dodaj opis',
    addKey: 'Dodaj klucz',
    addValue: 'Dodaj wartość',
    entry: 'Wpis',
    clear: 'Wyczyść',
    code: 'Kod',
    description: 'Opis',
    save: 'Zapisz',
    delete: 'Usuń',
    restore: 'Przywróć',
    deleted: 'Usunięto',
    removed: 'Usunięte',
    remove: 'Usuń',
    select: 'Wybierz',
    selected: 'Wybrane',
    cancel: 'Anuluj',
    confirm: 'Potwierdź',
    search: 'Szukaj',
    value: 'Wartość',
    error: 'Błąd',
    saved: 'Zapisano',
    valueType: 'Typ wartości',
    download: 'Pobierz',
    load: 'Załaduj',
    results: 'Wyniki',
    type: 'Rodzaj',
    status: 'Status',
    orderStatus: 'Status Zlecenia',
    statuses: 'Statusy',
    created: 'Utworzono',
    modified: 'Zmodyfikowano',
    edited: 'Edytowano',
    enabled: 'Włączony',
    disabled: 'Wyłączono',
    success: 'Udało się',
    update: 'Zaktualizuj',
    ordinalNumber: 'Liczba porządkowa',
    yes: 'Tak',
    no: 'Nie',
    on: 'Włącz',
    off: 'Wyłącz',
    of: 'z',
    archive: 'Archiwizuj',
    all: 'Wszystkie',
    areYouSure: 'Czy na pewno?',
    id: 'Id',
    multiOrderSupport: 'Wiele zamówień',
    from: 'Od',
    to: 'Do',
    auto: 'Automatyczny',
    manual: 'Ręczny',
    history: 'Historia',
    activationHistory: 'Historia aktywacji',
    details: 'Szczegóły',
    never: 'Nigdy',
    deviceSettings: 'Ustawienia urządzenia',
    devices: 'Urządzenia',
    showAdvanceMode: 'Pokaż tryb zaawansowany',
    warning: 'Uwaga',
    view: 'Widok',
    seeMore: 'Zobacz więcej',
    activate: 'Aktywuj',
    inactivate: 'Dezaktywuj',
    category: 'Kategoria',
    preview: 'Podgląd',
    clone: 'Klonuj',
    number: 'Numer',
    info: 'Informacja',
    plant: 'Fabryka',
    plantName: 'Nazwa fabryki',
    plantCode: 'Kod fabryki',
    help: 'Pomoc',
    comment: 'Komentarz',
    comments: 'Komentarze',
    none: 'Brak',
    back: 'Powrót',
    general: 'Ogólne',
    editDetails: 'Edytuj szczegóły',
    requiredData: 'Wymagane dane',
    auxiliaryData: 'Nadmiarowe dane',
    uncategorized: 'Nieskategoryzowane',
  },
  connection: {
    status: 'Status połączenia',
    disconnected: 'Rozłączono',
    reconnecting: 'Trwa ponowne łączenie',
    connected: 'Podłączono',
  },
  date: {
    date: 'Data',
    startDate: 'Data rozpoczęcia',
    endDate: 'Data zakończenia',
    plannedStartDate: 'Planowana data rozpoczęcia',
    plannedEndDate: 'Planowana data zakończenia',
    print: 'Data wydruku',
    start: 'Start',
    end: 'Koniec',
    plannedStart: 'Planowany start',
    plannedEnd: 'Planowany koniec',
  },
  auth: {
    signOut: 'Wyloguj',
    loginError: 'Błąd logowania',
    noAccess: 'Brak dostępu',
  },
  users: {
    name: 'Nazwa użytkownika',
    preferredArea: 'Preferowany obszar',
    preferredLine: 'Preferowana linia',
    preferences: 'Preferencje',
  },
  sidebar: {
    labelling: 'Etykietowanie',
    sku: 'SKU',
    labels: 'Etykiety',
    orders: 'Zlecenia',
    printRequests: 'Żądania Drukowania',
    requests: 'Żądania',
    settings: 'Ustawienia',
  },
  settings: {
    generalSettings: 'Ustawienia ogólne',
    editSection: 'Edytuj sekcję',
    youWantToEditThisSection: 'Czy jesteś pewny, że chcesz edytować tę sekcję ?',
    iWantToProceed: 'Chcę kontynuować',
    useInPlantStructure: `Użyj 'Obszarów'
      w strukturze fabryki`,
    thisAppearInPlantAssets: 'Pojawi się to w zasobach fabryki.',
    users: 'Użytkownicy',
    timezone: 'Strefa czasowa',
    mappings: 'Mapowania',
    ordersAndSku: 'Zlecenia i Sku',
    plantConfiguration: 'Konfiguracja fabryki',
    plantAssets: 'Aktywa fabryki',
    priority: 'Priorytet',
    setupIssues: 'Błędy konfiguracyjne',
    check: 'Brak dostępnych punktów etykietowania, sprawdź ustawienia',
    checkMappedOrders: `Żaden punkt etykietowania dla linii [%{value1}] nie obsługuje zlecenia typu [%{value2}]
      - zamapuj typ zlecenia na odpowiedni z punktów etykietowania w zakładce Ustawienia`,
    checkLabellingPoints: `Brak punktów etykietowania dla linii [%{value}]
      - zamapuj punkty etykietowania w zakładce Ustawienia`,
    areaInfo: `Dzieli fabrykę i grupuje linie w obszary.
      Po skonfigurowaniu obszarów nie powinno być wyłączane.`,
    editGeneralInfo: `„Ustawienia ogólne” mają wpływ na całość
      systemu i są zwykle ustawiane jednorazowo na początku wdrożenia`,
    multiOrderInfo: 'Nie ma możliwości obsługi wielu zamówień przy automatycznym starcie zlecenia',
    skuDeactivationInfo: 'Określa rezultat wykonania akcji dezaktywacji SKU',
    skuDeactivationRemoveMapping: 'Odpinaj zamapowane etykiety',
    skuDeactivationClearCustomFields: 'Czyść pola typu CUSTOM',
    skuDeactivationClearImportFields: 'Czyść pola typu IMPORT',
  },
  sku: {
    sku: 'SKU',
    skuDetails: 'Szczegóły SKU',
    turnOffSync: 'Wyłącz synchronizację',
    enableSync: 'Włącz synchronizację',
    fields: 'Pola SKU',
    mappingSaved: 'Mapowanie zapisane',
    editSku: 'Edytuj SKU',
    showAllFields: 'Pokaż wszystkie pola SKU',
    hideFields: 'Ukryj pola SKU',
    selectSkuField: 'Wybierz pole SKU',
    clickToShowDeleted: 'Kliknij aby pokazać usunięte SKU',
    select: 'Wybierz SKU',
    edit: 'Edycja listy powiodła się',
    active: 'Aktywne',
    inactive: 'Nieaktywne',
    noSku: 'Brak SKU',
    toEditTurnOffSync: 'Aby edytować wyłącz synchronizację',
    noMatchingLabels: 'Brak pasujących etykiet',
    labelTemplateAssignedToSku: 'Etykieta została przypisana do SKU',
    labelTemplateRemovedFromSku: 'Przypisanie etykiety do SKU zostało usunięte',
    skuDeactivation: 'Dezaktywacja SKU',
    onlyWithoutLabels: 'Tylko bez etykiet',
  },
  dictionary: {
    dictionary: 'Słownik',
    dictionaries: 'Słowniki',
    addDictionary: 'Dodaj słownik',
    editDictionary: 'Edytuj słownik',
    dictionaryName: 'Nazwa słownika',
    dictionaryEntry: 'Wpis ze słownika',
    key: 'Klucz',
    noEntriesInDictionary: 'Brak wpisów w słowniku',
    dictionaryCreated: 'Słownik został utworzony',
    dictionaryCreateFailed: 'Tworzenie słownika nie powiodło się',
    dictionaryUpdated: 'Słownik został zaktualizowany',
    dictionaryUpdateFailed: 'Aktualizacja słownika nie powiodła się',
    dictionaryDeleted: 'Słownik został usunięty',
    dictionaryDeleteFailed: 'Usunięcie słownika nie powiodło się',
  },
  labelTemplates: 'Szablony etykiet',
  labelTemplate: 'Szablon etykiety',
  label: {
    label: 'Etykieta',
    labels: 'Etykiety',
    addLabel: 'Dodaj etykietę',
    labelCreated: 'Etykieta utworzona',
    labelCreateFailed: 'Tworzenie etykiety nie powiodło się',
    labelUpdated: 'Etykieta zaktualizowana',
    labelUpdateFailed: 'Aktualizacja etykiety nie powiodła się',
    cloneLabelRules: 'Klonuj reguły etykiety',
    labelClone: 'Etykieta sklonowana',
    labelCloneFailed: 'Klonowanie etykiety nie powiodło się',
    selectLabel: 'Wybierz Etykietę',
    refreshSucceeded: 'Odświeżanie szablonów etykiet powiodło się',
    refreshFailed: 'Odświeżanie szablonów etykiet nie powiodło się',
    refreshLabelTemplates: 'Odśwież szablony etykiet',
    refreshingLabelTemplatesInProgress: 'Trwa pobieranie etykiet',
    refreshingLabelTemplatesCompleted: 'Pobieranie etykiet zostało zakończone z sukcesem',
    refreshingLabelTemplatesFailed: 'Wystąpił błąd w trakcie pobierania etykiet',
    refreshLabelPreview: 'Odśwież podgląd etykiety',
    labelPreview: 'Podgląd etykiety',
    download: 'Pobierz etykietę',
    noLabel: 'Bez etykiety',
    previewNotAvailable: 'Podgląd niedostępny',
    rotate: 'Obróć',
    clickToShowDeleted: 'Kliknij aby pokazać usunięte Etykiety',
    edit: 'Kliknij żeby edytować Etykiety',
    multipleLabels: 'Wiele Etykiet',
    changeLabel: 'Zmień szablon etykiety',
    labelInfo: 'Szablon wybrany tylko dla niektórych SKU',
    pickLabelInfo: 'Wybierz szablon etykiety, żeby zobaczyć podgląd',
    modifyTemplate: 'Modyfikuj szablon',
    uploadTemplate: 'Zaimportuj reguły z szablonu',
    importRule: 'Importuj regułę',
    pickFieldToEdit: 'Wybierz pole szablonu żeby edytować',
    offPreview: 'Wyłącz podgląd',
    selectAllForThisLabel: 'Zaznacz wszystkie dla tej etykiety',
    allForThisLabelSelected: 'Wszystkie dla tej etykiety są zaznaczone',
    downloadingLabels: 'Pobieranie etykiet',
    generatingPreviewTookTooLong: 'Generowanie podglądu trwało zbyt długo',
  },
  mapper: {
    skuFields: 'Pola SKU',
    thisCanBreakTheRules: 'Ta operacja może zepsuć reguły etykiety!',
    mappedSKU: 'Zmapowane SKU',
    selectToPreview: 'Kliknij aby podejrzeć',
    selectSkus: 'Wybierz SKU do podglądu',
    useSkuOrOrderDot: 'Użyj zmiennej "sku." lub "order." aby użyć pól',
    ruleSaved: 'Reguła zapisana',
    ruleSavedComment: 'Reguła została poprawnie zapisana',
    ruleNotSavedComment: 'Reguła nie została zapisana',
  },
  mappings: {
    mappings: 'Mapowania',
    addMappings: 'Dodaj mapowania',
  },
  template: {
    fields: 'Pola szablonu',
    field: 'Pole szablonu',
    selectToEdit: 'Kliknij żeby edytować',
    langSymbol: 'Język znaków',
    newTemplateName: 'Nowa nazwa szablonu',
    editTemplateProperties: 'Edytuj właściwości szablonu',
    cloneTemplate: 'Sklonuj szablon',
    clonedTemplateName: 'Nazwa klonowanego szablonu',
    addEntry: 'Dodaj wpis',
    addTemplate: 'Dodaj szablon',
    editTemplate: 'Edytuj szablon',
    templateName: 'Nazwa szablonu',
  },
  error: {
    internal: 'Błąd wewnętrzny',
    invalidPassword: 'Niewłaściwe hasło',
    descriptionIsTooLong: 'Opis jest za długi',
    nameIsTooLong: 'Nazwa jest za długa',
    nameIsInIncorrectFormat: 'Podana nazwa ma zły format',
    nameIsRequired: 'Nazwa jest wymagana',
    nameAlreadyExists: 'Podana nazwa już istnieje. Stwórz inną nazwę',
    shortNameIsTooLong: 'Skrócona nazwa jest za długa',
    orderNumberIsTooLong: 'Numer zlecenia jest za długi',
    numberAlreadyExists: 'Numer już istnieje. Stwórz inny numer',
    keyIsTooLong: 'Klucz jest za długi',
    keyIsRequired: 'Klucz jest wymagany',
    keyAlreadyExists: 'Klucz już istnieje. Stwórz inny klucz',
    codeIsTooLong: 'Kod jest za długi',
    codeAlreadyExists: 'Kod już istnieje. Stwórz inny kod',
    valueIsInNotExpectedType: 'Podana wartość nie jest oczekiwanego typu',
  },
  printerMapping: {
    printersMappings: 'Mapowanie Drukarek',
    printerMapping: 'Mapowanie Drukarki',
    enabledPrinterMapping: 'Włączone Mapowanie Drukarki',
  },
  printRequest: {
    printRequests: 'Żądania Drukowania',
    printRequest: 'Żądanie Drukowania',
    sendAgainToPrinter: 'Wyślij ponownie do drukarki',
    status: {
      CREATED: 'Utworzony',
      SCHEDULED: 'Zaplanowany',
      INPROGRESS: 'W trakcie',
      INPROGRESSWARMINGUP: 'W trakcie (rozgrzewanie)',
      COMPLETED: 'Zakończony',
      FAILED: 'Nieudany',
      ARCHIVE: 'Zarchiwizowany',
    },
  },
  request: {
    payload: 'Dane',
    request: 'Żądanie',
    requestDetails: 'Szczegóły żądania',
    retryTheRequest: 'Ponów żądanie',
    forceFailStatusRequest: 'Zmień status na nieudany',
    requestSucceed: 'Żądanie powiodło się',
    requestFailed: 'Żądanie nie powiodło się',
    resendInfoWarning: `Czy na pewno chcesz ręcznie ponowić request?
      Czynność ta wyśle request do urządzenia, co może spowodować nadpisanie jego wewnętrznego stanu.
      Nie ponawiaj ręcznie requestu, jeżeli nie jesteś w pełni świadomy konsekwencji tego działania`,
    types: {
      clear: 'Clear',
      print: 'Print',
      finish: 'Finish',
      getReport: 'Report',
    },
    pendingBlockingRequests: `Na drukarkach z wybranego punktu etykietowania wykonywane są inne żądania.
      Zaczekaj, aż poprzednie żądania zostaną zakończone przed wykonaniem kolejnej akcji`,
  },
  printer: {
    printer: 'Drukarka',
    printers: 'Drukarki',
    selectPrinter: 'Wybierz drukarkę',
    mappings: 'Mapowanie Drukarek',
    mapping: 'Mapowanie Drukarki',
    type: 'Typ Drukarki',
    printManually: 'Drukuj Manualnie',
    addPrinter: 'Dodaj drukarkę',
    editPrinter: 'Edytuj drukarkę',
    refreshPrinters: 'Odśwież drukarki',
    refreshSucceeded: 'Odświeżanie drukarek powiodło się',
    refreshFailed: 'Odświeżanie drukarek nie powiodło się',
    uploadedLabelsAvailable: 'Drukarka udostępnia wgrane etykiety',
  },
  area: {
    area: 'Obszar',
    areas: 'Obszary',
    areaName: 'Nazwa Obszaru',
    selectArea: 'Wybierz Obszar',
    addArea: 'Dodaj obszar',
    editArea: 'Edytuj obszar',
  },
  line: {
    line: 'Linia',
    lineName: 'Nazwa Linii',
    lines: 'Linie',
    selectLine: 'Wybierz Linie',
    addLine: 'Dodaj linię',
    editLine: 'Edytuj linię',
    orderStartValidationRule: 'Walidacja startu zlecenia',
  },
  labellingPoint: {
    labellingPoint: 'Punkt Etykietowania',
    labellingPoints: 'Punkty Etykietowania',
    labellingLevel: 'Poziom etykietowania',
    labellingLevels: 'Poziomy etykietowania',
    attachLabelToImportedSKUs: 'Dowiąż etykietę do nowych SKU przy imporcie',
    mappedLabellingPoints: 'Zmapowane Punkty Etykietowania',
    selectLabellingPoint: 'Wybierz Punkt Etykietowania',
    availableLabellingPoints: 'Dostępne Punkty Etykietowania',
    automaticPrintersCleaning: 'Automatyczne czyszczenie drukarek',
    automaticFinishingOnPrinters: 'Kończenie automatyczne',
    reportGeneration: 'Generowanie raportów',
    armingDevices: 'Uzbrajanie urządzeń',
    atTheStartOfTheOrder: 'Przy starcie zlecenia',
    atTheUserRequest: 'Na żądanie użytkownika',
    addLabellingPoint: 'Dodaj punkt etykietowania',
    editLabellingPoint: 'Edytuj punkt etykietowania',
  },
  form: {
    form: 'Formularz',
    enterValue: 'Wprowadź wartość',
    refresh: 'Odśwież',
    title: 'Tytuł',
    send: 'Wyślij',
    optional: 'Opcjonalnie',
    printSend: 'Drukuj/Wyślij',
    dataForm: 'Formularz Danych',
    noData: 'Brak Danych',
    dataSource: 'Źródło Danych',
    url: 'Wpisz adres URL do wagi',
    orderFieldInfo: 'Wybierz pole zlecenia',
    orderField: 'Pole Zlecenia',
    label: 'Nazwa pola',
    required: 'Pole wymagane',
    checkedByDefault: 'Domyślnie zaznaczone',
    description: 'Opis',
    valuesCommaSeparated: 'Wartości (oddzielone przecinkami)',
    values: 'Wartości',
    selectValuesSource: 'Źródło wartości do wyboru',
    text: 'Tekst',
    input: 'Pole tekstowe',
    select: 'Lista wyboru',
    textarea: 'Obszar tekstowy',
    checkbox: 'Pole wyboru',
    checklist: 'Pole wielokrotnego wyboru',
    radios: 'Pole opcji',
    textField: 'Tekst statyczny',
    addField: 'Dodaj pole',
    edit: 'Edytuj pole',
    activeFileds: 'Aktywne pola',
    selectFieldType: 'Wybierz typ pola',
    fieldType: 'Typ pola',
    inputType: 'Typ odpowiedzi',
    labelValue: 'Wpisz pytanie / nazwę pola',
    descriptionPrompt: 'Wpisz opis / instrukcję',
    fieldDescription: 'Opis pola',
    fieldValues: 'Wypisz wartości pól',
    fieldValuesSource: 'Wybierz źródło danych listy wyboru',
    fieldValuesFromFieldDictionary: 'Wybrane pole jest polem typu Słownik i jest już powiązane z konkretnym Słownikiem',
    selectedPropertyIsOfTypeDictionary: 'Wybrane pole jest polem typu Słownik',
    name: 'Nazwa',
    type: 'Typ',
    editField: 'Wybierz pole aby rozpocząć edycję',
    startField: 'Dodaj pole aby rozpocząć edycję',
    searchByOrderNumber: 'Wyszukaj po numerze zlecenia',
    rowsOnThePage: 'Wierszy na stronie',
    showing: 'Wyświetlanie',
    results: 'Wyników',
    addComment: 'Dodaj komentarz',
    areaSuccessfullyRemoved: 'Obszar został pomyślnie usunięty',
    areaSuccessfullyCreated: 'Obszar został pomyślnie utworzony',
    lineSuccessfullyRemoved: 'Linia została pomyślnie usunięta',
    lineSuccessfullyCreated: 'Linia została pomyślnie utworzona',
    labellingPointSuccessfullyRemoved: 'Punkt etykietowania został pomyślnie usunięty',
    labellingPointSuccessfullyCreated: 'Punkt etykietowania został pomyślnie utworzony',
    printerSuccessfullyRemoved: 'Drukarka została pomyślnie usunięta',
    printerSuccessfullyCreated: 'Drukarka została pomyślnie utworzona',
    orderFieldSuccessfullyRemoved: 'Pole zlecenia zostało pomyślnie usunięte',
    orderFieldSuccessfullyCreated: 'Pole zlecenia zostało pomyślnie utworzone',
    skuFieldSuccessfullyCreated: 'Pole sku zostało pomyślnie utworzone',
    orderSuccessfullyCreated: 'Zlecenie zostało pomyślnie utworzone',
    dictionarySuccessfullyRemoved: 'Słownik został pomyślnie usunięty',
  },
  lineLabellingPoint: {
    lineLabellingPoint: 'Mapowanie Linia Punkt Etykietowania',
    lineLabellingPoints: 'Mapowania Linie Punkty Etykietowania',
    labellingPointsMapping: 'Mapowanie Punktów Etykietowania',
    labelsMapping: 'Mapowanie Etykiet',
    labellingPointMapping: 'Mapowanie Punktu Etykietowania',
  },
  order: {
    activeOrders: 'Aktywne zlecenia',
    activeOrderDetails: 'Szczegóły Aktywnego Zlecenia',
    orderActivationHistory: 'Historia Aktywacji Zlecenia',
    runOrderOn: 'Uruchom zlecenie na punkcie etykietowania',
    stopOrderOn: 'Zatrzymaj zlecenie na punkcie etykietowania',
    step: 'Krok',
    actions: 'Akcje',
    details: 'Szczegóły',
    saveOrder: 'Zapisz zlecenie',
    fillOrderFields: 'Wypełnij pola zamówienia',
    orderFieldsUpdated: 'Pola zamówienia zaktualizowane',
    orderActivated: 'Aktywacje zlecenia: ',
    orderId: 'ID zlecenia',
    order: 'Zlecenie',
    orders: 'Zlecenia',
    finishOrder: 'Zakończ zlecenie',
    finish: 'Zakończ',
    createOrder: 'Utwórz zlecenie',
    putOrderNumber: 'Podaj numer zlecenia',
    stop: 'Stop',
    orderCompleted: 'Zlecenie zakończone',
    hold: 'Wstrzymaj',
    orderType: 'Typ Zlecenia',
    addOrderType: 'Dodaj Typ Zlecenia',
    selectOrderType: 'Wybierz Typ Zlecenia',
    orderTypes: 'Typy Zlecenia',
    allowAllOrderTypes: 'Dozwól wszystkie typy zleceń',
    mappings: 'Mapowanie Typu Zlecenia',
    displayName: 'Wyświetlana Nazwa',
    start: 'Start',
    activate: 'Aktywuj Zlecenie',
    orderNumber: 'Nr Zlecenia',
    orderDetails: 'Szczegóły Zlecenia',
    selectOrder: 'Wybierz zlecenie',
    fields: 'Pola zlecenia',
    field: 'Pole zlecenia',
    addField: 'Dodaj pole zlecenia',
    editField: 'Edytuj pole zlecenia',
    add: 'Dodaj zlecenie',
    badgeColor: 'Kolor Oznaczenia',
    selectColor: 'Wybierz kolor',
    actionCannotBeUndone: 'Ta czynność nie może zostać cofnięta',
    status: {
      CLOSED: 'CLOSED',
      INPROGRESS: 'ROZPOCZĘTE',
      ONHOLD: 'WSTRZYMANE',
      COMPLETED: 'ZAKOŃCZONE',
      READY: 'ZWOLNIONE',
      ACTIVATED: 'AKTYWOWANE',
    },
    executionStatus: {
      ACTIVE: 'Aktywne',
      INACTIVE: 'Nieaktywne',
    },
  },
  selectors: {
    period: 'Okres',
    moment: 'Moment',
    selectExactDates: 'Wybierz dokładne daty',
    dateTo: 'Data do',
    dateFrom: 'Data od',
    shift: 'Zmiana',
    day: 'Dzień',
    week: 'Tydzień',
    month: 'Miesiąc',
    today: 'Dzisiaj',
    yesterday: 'Wczoraj',
    thisWeek: 'Ten tydzień',
    previousWeek: 'Poprzedni tydzień',
    thisMonth: 'Ten miesiąc',
    lastThreeMonths: 'Ostatnie 3 miesiące',
    previousMonth: 'Poprzedni miesiąc',
  },
  time: {
    days: 'dni',
    fromPrint: 'Czas od wydruku',
    duration: 'Czas trwania',
    timeRange: 'Zakres czasu',
  },
  production: {
    ofThePlan: 'osiągnięto %{value}% tej wartości',
    toAchieveTarget: 'Aktualna oczekiwana produkcja do osiągnięcia celu:',
    noPlan: 'Brak planu',
  },
};
