import { HubConnectionBuilder, LogLevel, HubConnectionState } from '@microsoft/signalr';
import store from './store';

export default {
  // eslint-disable-next-line no-unused-vars
  install(Vue) {
    const options = {
      accessTokenFactory: () => store.getters['oidcStore/oidcAccessToken'],
    };

    const connection = new HubConnectionBuilder()
      .withUrl('/DataTransferHub', options)
      .configureLogging(LogLevel.Error)
      .build();

    // use new Vue instance as an event bus
    const dataTransferHub = new Vue();
    // every component will use this.$questionHub to access the event bus
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$dataTransferHub = dataTransferHub;

    async function start() {
      if (connection.state !== HubConnectionState.Disconnected) {
        return;
      }
      try {
        await connection.start();
      } catch (err) {
        setTimeout(start, 5000);
      }
    }

    // Forward server side SignalR events through $questionHub, where components will listen to them
    connection.on('LabelPreview', (requestRefId, previewResult) => {
      dataTransferHub.$emit('label-preview-returned', { requestRefId, previewResult });
    });

    connection.on('GetLabels', requestRefId => {
      dataTransferHub.$emit('get-labels-returned', { requestRefId });
    });

    connection.on('GetPrinters', requestRefId => {
      dataTransferHub.$emit('get-printers-returned', { requestRefId });
    });

    // eslint-disable-next-line no-param-reassign,func-names
    Vue.prototype.$startDataTransferHub = function () {
      start();
    };
  },
};
