export default {
  general: {
    name: 'Name',
    shortName: 'Short name',
    addName: 'Add name',
    addDescription: 'Add description',
    addKey: 'Add key',
    addValue: 'Add value',
    entry: 'Entry',
    clear: 'Clear',
    code: 'Code',
    description: 'Description',
    save: 'Save',
    delete: 'Delete',
    restore: 'Restore',
    deleted: 'Deleted',
    removed: 'Removed',
    remove: 'Remove',
    select: 'Select',
    selected: 'Selected',
    cancel: 'Cancel',
    confirm: 'Confirm',
    search: 'Search',
    value: 'Value',
    error: 'Error',
    saved: 'Saved',
    valueType: 'Value type',
    download: 'Download',
    load: 'Load',
    results: 'Results',
    type: 'Type',
    statuses: 'Statuses',
    status: 'Status',
    orderStatus: 'Order Status',
    created: 'Created',
    modified: 'Modified',
    edited: 'Edited',
    enabled: 'Enabled',
    disabled: 'Disabled',
    success: 'Success',
    update: 'Update',
    ordinalNumber: 'Ordinal Number',
    yes: 'Yes',
    no: 'No',
    on: 'On',
    off: 'Off',
    of: 'of',
    archive: 'Archive',
    all: 'All',
    areYouSure: 'Are you sure?',
    id: 'Id',
    multiOrderSupport: 'Multi Order',
    from: 'From',
    to: 'To',
    auto: 'Auto',
    manual: 'Manual',
    history: 'History',
    activationHistory: 'Activation history',
    details: 'Details',
    never: 'Never',
    deviceSettings: 'Device settings',
    devices: 'Devices',
    showAdvanceMode: 'Show advance mode',
    warning: 'Warning',
    view: 'View',
    seeMore: 'See more',
    activate: 'Activate',
    inactivate: 'Inactivate',
    category: 'Category',
    preview: 'Preview',
    clone: 'Clone',
    number: 'Number',
    info: 'Info',
    plant: 'Plant',
    plantName: 'Plant name',
    plantCode: 'Plant code',
    help: 'Help',
    comment: 'Comment',
    comments: 'Comments',
    none: 'None',
    back: 'Back',
    general: 'General',
    editDetails: 'Edit details',
    requiredData: 'Required data',
    auxiliaryData: 'Auxiliary data',
    uncategorized: 'Uncategorized',
  },
  connection: {
    status: 'Connection status',
    disconnected: 'Disconnected',
    reconnecting: 'Reconnecting',
    connected: 'Connected',
  },
  date: {
    date: 'Date',
    startDate: 'Start Date',
    endDate: 'End Date',
    plannedStartDate: 'Planned start date',
    plannedEndDate: 'Planned end date',
    print: 'Print Date',
    start: 'Start',
    end: 'End',
    plannedStart: 'Planned start',
    plannedEnd: 'Planned end',
  },
  auth: {
    signOut: 'Logout',
    loginError: 'Login error',
    noAccess: 'No access',
  },
  users: {
    name: 'User name',
    preferredArea: 'Preferred area',
    preferredLine: 'Preferred line',
    preferences: 'Preferences',
  },
  sidebar: {
    labelling: 'Labelling',
    sku: 'SKU',
    labels: 'Labels',
    orders: 'Orders',
    printRequests: 'Print Requests',
    requests: 'Requests',
    settings: 'Settings',
  },
  settings: {
    generalSettings: 'General settings',
    editSection: 'Edit section',
    youWantToEditThisSection: 'Are you sure you want to edit this section ?',
    iWantToProceed: 'I want to proceed',
    useInPlantStructure: `Use 'Areas'
      in plant structure`,
    thisAppearInPlantAssets: 'This will appear in plant assets.',
    users: 'Users',
    timezone: 'Timezone',
    mappings: 'Mappings',
    ordersAndSku: 'Orders and Sku',
    plantConfiguration: 'Plant Configuration',
    plantAssets: 'Plant assets',
    priority: 'Priority',
    setupIssues: 'Setup issues',
    check: 'Labelling points not available, check settings',
    checkMappedOrders: `None labelling point with line [%{value1}] matches Order Type [%{value2}]
      -> map order type to the right labelling points in the Settings tab`,
    checkLabellingPoints: `Labelling points not available for line [%{value}]
      - map labelling points in Settings tab`,
    areaInfo: `It divides the plant into areas and groups lines
      within each area. Once used, the “Areas” should not be switched off.`,
    editGeneralInfo: `The “General Settings” have an impact on the entire
      system and are usually set once at the beginning of the implementation.`,
    multiOrderInfo: 'It is not possible to handle multi orders with arming devices at the start of an order',
    skuDeactivationInfo: 'Specifies the result of SKU deactivation action',
    skuDeactivationRemoveMapping: 'Remove label mapping',
    skuDeactivationClearCustomFields: 'Clear CUSTOM type fields',
    skuDeactivationClearImportFields: 'Clear IMPORT type fields',
  },
  sku: {
    sku: 'SKU',
    skuDetails: 'SKU details',
    turnOffSync: 'Turn off synchronization',
    enableSync: 'Enable synchronization',
    fields: 'SKU fields',
    mappingSaved: 'Mapping saved',
    editSku: 'Edit SKU',
    showAllFields: 'Show all SKU fields',
    hideFields: 'Hide SKU fields',
    selectSkuField: 'Select Sku Field',
    clickToShowDeleted: 'Click to show removed SKU',
    select: 'Select SKU',
    edit: 'List edit is successful',
    active: 'Active',
    inactive: 'Inactive',
    noSku: 'No SKU',
    toEditTurnOffSync: 'To edit turn off the synchronization',
    noMatchingLabels: 'No matching templates',
    labelTemplateAssignedToSku: 'The label has been assigned to the SKU',
    labelTemplateRemovedFromSku: 'The label assignment to the SKU has been removed',
    skuDeactivation: 'SKU deactivation',
    onlyWithoutLabels: 'Only without labels',
  },
  dictionary: {
    dictionary: 'Dictionary',
    dictionaries: 'Dictionaries',
    addDictionary: 'Add dictionary',
    editDictionary: 'Edit dictionary',
    dictionaryName: 'Dictionary name',
    dictionaryEntry: 'Dictionary entry',
    key: 'Key',
    noEntriesInDictionary: 'No entries in the dictionary',
    dictionaryCreated: 'Dictionary created',
    dictionaryCreateFailed: 'Dictionary creation failed',
    dictionaryUpdated: 'Dictionary updated',
    dictionaryUpdateFailed: 'Dictionary update failed',
    dictionaryDeleted: 'Dictionary deleted',
    dictionaryDeleteFailed: 'Dictionary deletion failed',
  },
  labelTemplates: 'Label templates',
  labelTemplate: 'Label template',
  label: {
    label: 'Label',
    labels: 'Labels',
    addLabel: 'Add label',
    labelCreated: 'Label created',
    labelCreateFailed: 'Label creation failed',
    labelUpdated: 'Label updated',
    labelUpdateFailed: 'Label update failed',
    labelClone: 'Label cloned',
    labelCloneFailed: 'Label clone failed',
    cloneLabelRules: 'Clone label rules',
    selectLabel: 'Select Label',
    refreshSucceeded: 'Label templates refresh succeeded',
    refreshFailed: 'Label templates refresh failed',
    refreshLabelTemplates: 'Refresh label templates',
    refreshingLabelTemplatesInProgress: 'Templates are being downloaded',
    refreshingLabelTemplatesCompleted: 'Template download has been completed',
    refreshingLabelTemplatesFailed: 'An error occured while trying to download label templates',
    refreshLabelPreview: 'Refresh Label Preview',
    labelPreview: 'Label Preview',
    download: 'Download label',
    noLabel: 'No Label',
    previewNotAvailable: 'Preview not available',
    rotate: 'Rotate',
    clickToShowDeleted: 'Click to show removed Labels',
    edit: 'Click to edit Labels',
    multipleLabels: 'Multiple Labels',
    changeLabel: 'Change label template',
    labelInfo: 'Template selected for certain SKUs only',
    pickLabelInfo: 'Select a label template to see a preview',
    modifyTemplate: 'Modify template',
    uploadTemplate: 'Import rule from template',
    importRule: 'Import rule',
    pickFieldToEdit: 'Select a field to edit',
    offPreview: 'Preview off',
    selectAllForThisLabel: 'Select all for this label',
    allForThisLabelSelected: 'All for this label are selected',
    downloadingLabels: 'Downloading labels',
    generatingPreviewTookTooLong: 'Generating preview took too long',
  },
  mapper: {
    skuFields: 'SKU fields',
    thisCanBreakTheRules: 'This operation can break label rules!',
    mappedSKU: 'Mapped SKUs',
    selectToPreview: 'Click to preview',
    selectSkus: 'Select SKUs to preview',
    useSkuOrOrderDot: 'Use variable "sku." or "order." to get fields',
    ruleSaved: 'Rule saved',
    ruleSavedComment: 'Rule has been successfully saved',
    ruleNotSavedComment: 'Rule has not been saved',
  },
  mappings: {
    mappings: 'Mappings',
    addMappings: 'Add mappings',
  },
  template: {
    fields: 'Template fields',
    field: 'Template field',
    selectToEdit: 'Click to edit',
    langSymbol: 'Lang symbol',
    newTemplateName: 'New template name',
    editTemplateProperties: 'Edit template properties',
    cloneTemplate: 'Clone template',
    clonedTemplateName: 'Cloned template name',
    addEntry: 'Add entry',
    addTemplate: 'Add template',
    editTemplate: 'Edit template',
    templateName: 'Template name',
  },
  error: {
    internal: 'Internal error',
    invalidPassword: 'Invalid password',
    descriptionIsTooLong: 'Description is too long',
    nameIsTooLong: 'Name is too long',
    nameIsInIncorrectFormat: 'Name is in incorrect format',
    nameIsRequired: 'Name is required',
    nameAlreadyExists: 'Name already exists. Create a different name',
    shortNameIsTooLong: 'Short name is too long',
    orderNumberIsTooLong: 'Order number is too long',
    numberAlreadyExists: 'Number already exists. Create a different number',
    keyIsTooLong: 'Key is too long',
    keyIsRequired: 'Key is required',
    keyAlreadyExists: 'Key already exists. Create a different key',
    codeIsTooLong: 'Code is too long',
    codeAlreadyExists: 'Code already exists. Create a different code',
    valueIsInNotExpectedType: 'Given value is not in the expected format',
  },
  printerMapping: {
    printerMapping: 'Printer Mapping',
    printersMappings: 'Printers Mapping',
    enabledPrinterMapping: 'Enabled Printer Mapping',
  },
  printRequest: {
    printRequest: 'Print request',
    printRequests: 'Print requests',
    sendAgainToPrinter: 'Send again to printer',
    status: {
      CREATED: 'Created',
      SCHEDULED: 'Scheduled',
      INPROGRESS: 'InProgress',
      INPROGRESSWARMINGUP: 'InProgress (warming up)',
      COMPLETED: 'Completed',
      FAILED: 'Failed',
      ARCHIVE: 'Archive',
    },
  },
  request: {
    payload: 'Payload',
    request: 'Request',
    requestDetails: 'Request details',
    retryTheRequest: 'Retry the request',
    forceFailStatusRequest: 'Change status to failed',
    requestSucceed: 'Request succeed',
    requestFailed: 'Request failed',
    resendInfoWarning: `Are you sure you want to manually retry the request?
      This action will send a request to the device, which may overwrite it's internal state.
      Don't retry the request manually if you are not fully aware of the consequences of doing so`,
    types: {
      clear: 'Clear',
      print: 'Print',
      finish: 'Finish',
      getReport: 'Report',
    },
    pendingBlockingRequests: `In progress requests exist on printers from selected labelling point.
      Wait for previous requests to finish before performing the new action`,
  },
  printer: {
    printer: 'Printer',
    printers: 'Printers',
    selectPrinter: 'Select Printer',
    mappings: 'Printers Mapping',
    mapping: 'Printer Mapping',
    type: 'Printer Type',
    printManually: 'Print Manually',
    addPrinter: 'Add printer',
    editPrinter: 'Edit printer',
    refreshPrinters: 'Refresh printers',
    refreshSucceeded: 'Printers refresh succeeded',
    refreshFailed: 'Printers refresh failed',
    uploadedLabelsAvailable: 'Printer shares uploaded labels',
  },
  area: {
    area: 'Area',
    areas: 'Areas',
    areaName: 'Area Name',
    selectArea: 'Select Area',
    addArea: 'Add area',
    editArea: 'Edit area',
  },
  line: {
    line: 'Line',
    lines: 'Lines',
    lineName: 'Line Name',
    selectLine: 'Select Line',
    addLine: 'Add line',
    editLine: 'Edit line',
    orderStartValidationRule: 'Order start validation rule',
  },
  labellingPoint: {
    labellingPoint: 'Labelling Point',
    labellingPoints: 'Labelling Points',
    labellingLevel: 'Labelling level',
    labellingLevels: 'Labelling levels',
    attachLabelToImportedSKUs: 'Attach the label to new SKUs on import',
    mappedLabellingPoints: 'Mapped Labelling Points',
    selectLabellingPoint: 'Select Labelling Point',
    availableLabellingPoints: 'Available Labelling Points',
    automaticPrintersCleaning: 'Automatic printers cleaning',
    automaticFinishingOnPrinters: 'Automatic finishing',
    reportGeneration: 'Report generation',
    armingDevices: 'Arming devices',
    atTheStartOfTheOrder: 'At the start of the order',
    atTheUserRequest: `At the
      user's request`,
    addLabellingPoint: 'Add labelling point',
    editLabellingPoint: 'Edit labelling point',
  },
  form: {
    form: 'Form',
    enterValue: 'Enter value',
    refresh: 'Refresh',
    send: 'Send',
    optional: 'Optional',
    printSend: 'Print/Send',
    title: 'Title',
    dataForm: 'Data Form',
    values: 'Values',
    selectValuesSource: 'Source of values to choose from',
    noData: 'No data',
    dataSource: 'Data Source',
    url: 'Type adress URL for scale',
    orderFieldInfo: 'Select order field',
    orderField: 'Order Field',
    label: 'Label',
    required: 'Required',
    checkedByDefault: 'Checked by default',
    description: 'Description',
    valuesCommaSeparated: 'Values (comma separated)',
    text: 'Text',
    input: 'Input',
    select: 'Select',
    textarea: 'Textarea',
    checkbox: 'Checkbox',
    checklist: 'Checklist',
    radios: 'Radio',
    textField: 'Static text',
    addField: 'Add field',
    edit: 'Edit field',
    activeFileds: 'Active fields',
    selectFieldType: 'Select field type',
    fieldType: 'Field type',
    inputType: 'Select input type',
    labelValue: 'Enter question / field name',
    descriptionPrompt: 'Enter the descrption / instrutcion',
    fieldDescription: 'Field description',
    fieldValues: 'Enter field values',
    fieldValuesSource: 'Select field values source',
    fieldValuesFromFieldDictionary: 'Selected property is of type Dictionary and is linked to the specific Dictionary',
    selectedPropertyIsOfTypeDictionary: 'Selected property is of type Dictionary',
    name: 'Name',
    type: 'Type',
    editField: 'Select a field to start editing',
    startField: 'Add field to start editing',
    searchByOrderNumber: 'Search by order number',
    rowsOnThePage: 'Rows on the page',
    showing: 'Showing',
    results: 'Results',
    addComment: 'Add comment',
    areaSuccessfullyRemoved: 'Area successfully removed',
    areaSuccessfullyCreated: 'Area successfully created',
    lineSuccessfullyRemoved: 'Line successfully removed',
    lineSuccessfullyCreated: 'Line successfully created',
    labellingPointSuccessfullyRemoved: 'Labelling point successfully removed',
    labellingPointSuccessfullyCreated: 'Labelling point successfully created',
    printerSuccessfullyRemoved: 'Printer successfully removed',
    printerSuccessfullyCreated: 'Printer successfully created',
    orderFieldSuccessfullyRemoved: 'Order field successfully removed',
    orderFieldSuccessfullyCreated: 'Order field successfully created',
    skuFieldSuccessfullyCreated: 'Sku field successfully created',
    orderSuccessfullyCreated: 'Order successfully created',
  },
  lineLabellingPoint: {
    lineLabellingPoint: 'Mapping Line To Labelling Point',
    lineLabellingPoints: 'Mapping Lines To Labelling Points',
    labellingPointsMapping: 'Labelling Points Mapping',
    labelsMapping: 'Labels Mapping',
    labellingPointMapping: 'Labelling Point Mapping',
  },
  order: {
    activeOrders: 'Active orders',
    activeOrderDetails: 'Active Order Details',
    orderActivationHistory: 'Order Activation History',
    runOrderOn: 'Run order on labelling point',
    stopOrderOn: 'Stop order on labelling point',
    step: 'Step',
    actions: 'Actions',
    details: 'Details',
    saveOrder: 'Save order',
    fillOrderFields: 'Fill order fields',
    orderFieldsUpdated: 'Order fields updated',
    orderActivated: 'Order activated:',
    orderId: 'Order ID',
    order: 'Order',
    orders: 'Orders',
    finishOrder: 'Finish order',
    finish: 'Finish',
    createOrder: 'Create order',
    putOrderNumber: 'Type order number',
    stop: 'Stop',
    orderCompleted: 'Order completed',
    hold: 'Hold',
    orderType: 'Order Type',
    allowAllOrderTypes: 'Allow all order types',
    addOrderType: 'Add Order Type',
    selectOrderType: 'Select Order Type',
    orderTypes: 'Order Types',
    mappings: 'Order Type Mapping',
    displayName: 'Display Name',
    start: 'Start',
    activate: 'Activate Order',
    orderNumber: 'Order No.',
    orderDetails: 'Order details',
    fields: 'Order fields',
    field: 'Order field',
    addField: 'Add order field',
    editField: 'Edit order field',
    add: 'Add order',
    badgeColor: 'Badge Color',
    selectColor: 'Select color',
    actionCannotBeUndone: 'This action cannot be undone',
    status: {
      CLOSED: 'CLOSED',
      INPROGRESS: 'STARTED',
      ONHOLD: 'ON HOLD',
      COMPLETED: 'COMPLETED',
      READY: 'RELEASED',
      ACTIVATED: 'ACTIVATED',
    },
    executionStatus: {
      ACTIVE: 'Active',
      INACTIVE: 'Inactive',
    },
  },
  selectors: {
    period: 'Period',
    moment: 'Moment',
    dateTo: 'Date to',
    dateFrom: 'Date from',
    shift: 'Shift',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    today: 'Today',
    yesterday: 'Yesterday',
    thisWeek: 'This week',
    previousWeek: 'Previous week',
    thisMonth: 'This month',
    previousMonth: 'Previous month',
    lastThreeMonths: 'Last 3 months',
    selectExactDates: 'Select exact dates',
  },
  time: {
    days: 'days',
    fromPrint: 'Time from print',
    duration: 'Duration',
    timeRange: 'Time range',
  },
  production: {
    ofThePlan: '%{value}% of this value has been achieved',
    toAchieveTarget: 'Current expected production to achieve target:',
    noPlan: 'No plan',
  },
};
